

import { getFunctions, httpsCallable } from "firebase/functions";
import snsWebSdk from '@sumsub/websdk';
import { getAuth } from 'firebase/auth'
import sumsubStyles from '@/utils/samsubStyles'

import { ref, onMounted } from 'vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'KycVerification',
  components: { Icon },
  props: {
    lang: { type: String, required: true }
  },
  setup(props: Record<string, unknown>): Record<string, unknown> {
    const auth = getAuth()
    const email = auth.currentUser?.email
    const busy = ref<boolean>(false)

    const getNewAccessToken = async () => {
      try	{
        return (await getAccessToken()).data
      } catch (e) {
        console.log('Failed to get token', (e as Error).message)
        return Promise.reject()
      }
    }

    const onStartVerification = async () => {
      busy.value = true
      getNewAccessToken()
      .then((token) => {
        launchWebSdk(token, email || '')
      })
      .catch((e) => {
        console.error('Failed to get Access token', e.message)
      })
    }

    const getAccessToken = httpsCallable<null, string>(getFunctions(), 'dashboardSSToken')

    onMounted(() => {
      onStartVerification()
    })

    //todo: copy/pasted from example. refactor needed
    const launchWebSdk = (accessToken: string, applicantEmail?: string /*, applicantPhone?: string, customI18nMessages?: any*/) => {
      let snsWebSdkInstance = snsWebSdk.init(
          accessToken,
          // token update callback, must return Promise
          // Access token expired
          // get a new one and pass it to the callback to re-initiate the WebSDK
          () => getNewAccessToken()
      )
      .withConf({
        lang: String(props.lang), //language of WebSDK texts and comments (ISO 639-1 format)
        email: applicantEmail,
        // phone: applicantPhone,
        // i18n: customI18nMessages, //JSON of custom SDK Translations
        uiConf: {
          customCssStr: sumsubStyles
          // customCss: `${document.location.protocol}//${document.location.host}/samsab/styles.css`
          // URL to css file in case you need change it dynamically from the code
          // the similar setting at Customizations tab will rewrite customCss
          // you may also use to pass string with plain styles `customCssStr:`
        },
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true})
      // see below what kind of messages WebSDK generates
      .on("idCheck.onReady", () => {
        busy.value = false
      })
      .on('idCheck.stepCompleted', (payload) => {
        console.log('stepCompleted', payload)
      })
      .on('idCheck.onError', (error) => {
        console.log('onError', error)
      })
      .onMessage((type, payload) => {
        console.log('onMessage', type, payload)
      })
      .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch('#sumsub-websdk-container')
    }

    return { onStartVerification, busy }
  }
}

