import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1362b9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kyc-verification" }
const _hoisted_2 = { id: "sumsub-websdk-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.busy)
      ? (_openBlock(), _createBlock(_component_icon, {
          key: 0,
          name: "loading-big",
          class: "kyc-verification__loading",
          width: 120,
          height: 120
        }))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
      [_vShow, !$setup.busy]
    ])
  ]))
}