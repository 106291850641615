export default `
:root {
  /* --white-color: #fff; */
  --primary-color: #4a4a4a;
  --red-color: #F4825F;
  /* --dark-red-color: #e04f5f; */
  --orange-color: #74af26;
  --gray-color: #ddd;
  --light-grey-color: #eee;
  --success-color: #74af26;
  /* --section-shadow-color: #00000014;
  --lang-selector-shadow-color: #0000004d;
  --bullet-shadow-color: #dedede;
  --step-mobile: #d9deeb;
  --facebook-color: #3b5998;
  --google-color: #dd4b39;
  --textarea-background-color: #00000005;
  --continue-gray: #d9dfe2;
  --continue-vaiolet: #4b55a5; */
  --green-color: #74af26;
}
body {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat, sans-serif;
}
button {
  border-radius: 4px;
}

button.submit,
button[type='submit'] {
  background: var(--green-color);
}

button.submit:active:not(:disabled),
button.submit:hover:not(:disabled):not(.disabled):not(:active),
button[type='submit']:active:not(:disabled),
button[type='submit']:hover:not(:disabled):not(.disabled):not(:active) {
  background: var(--green-color);
}
.checkbox,
.radio-item {
  line-height: 1.2;
}
.round-icon,
#loader .round-icon {
  background: var(--green-color);
}

.steps.mobile .step.active {
  background-color: var(--green-color);
  border-color: var(--green-color);
}
`;
