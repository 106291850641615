
import Icon from '@/components/Icon.vue';
import Banner from '@/components/Banner.vue';
import KycNotEligibleBanner from '@/components/KycNotEligibleBanner.vue'
import KycVerification from '@/components/KycVerification.vue';
import { computed, readonly, ref, defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import constatns from '@/utils/constants';

const STEPS = {
  DOCUMENT: 'document',
  LIVELINESS: 'liveliness',
};
const STEPS_DATA = {
  [STEPS.DOCUMENT]: { title: 'Identity Document', passed: false },
  [STEPS.LIVELINESS]: { title: 'Live Selfie', passed: false },
};

export default defineComponent({
  name: 'Kyc',
  components: { Icon, Banner, KycVerification, KycNotEligibleBanner },
  setup(): Record<string, unknown> {
    const store = useStore();
    const router = useRouter()
    const steps = readonly(STEPS);
    const stepsData = readonly(STEPS_DATA);
    const CONSTS = readonly(constatns);
    const userStates = CONSTS.USER_STATES;
    const kycVerificationLang = ref('en');
    const isEligible = computed<boolean>(
      () => store.getters['user/currentState'] === userStates.KYC_ELIGIBLE
    );
    const isNotEligible = computed<boolean>(
      () => store.getters['user/currentState'] === userStates.KYC_NOT_ELIGIBLE
    );
    const isKycStarted = computed<boolean>(
      () => store.getters['user/currentState'] === userStates.KYC_STARTED
    );
    const isKycFailed = computed<boolean>(
      () => store.getters['user/currentState'] === userStates.KYC_FAILED
    );
    const isKycNotComplited = computed<boolean>(
      () => store.getters['user/currentState'] === userStates.KYC_NOT_COMPLETED
    );
    const isKycSuccess = computed<boolean>(
      () => store.getters['user/currentState'] === userStates.KYC_SUCCESS
    );
    const setKycStarted = () => store.dispatch('user/setUserKycStarted');
    const onSelectLang = (value: string): void => {
      kycVerificationLang.value = value;
    };
    watch(isKycSuccess, (v: boolean): void => {
      if (v) router.push({ name: CONSTS.NAV_ROUTES[CONSTS.SUBNAV.ALLOCATION].NAME })
    })
    return {
      steps,
      CONSTS,
      stepsData,
      isEligible,
      isKycFailed,
      isKycStarted,
      onSelectLang,
      isKycSuccess,
      isNotEligible,
      setKycStarted,
      isKycNotComplited,
      kycVerificationLang,
    };
  },
});
