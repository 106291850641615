
import Banner from '@/components/Banner.vue'
import CONSTS from '@/utils/constants';

export default {
  name: 'KycNotEligibleBanner',
  components: { Banner },
  setup (): Record<string, unknown> {
    return { CONSTS }
  }
}
