import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!

  return (_openBlock(), _createBlock(_component_banner, {
    type: $setup.CONSTS.ALERT_TYPES.ERROR,
    title: "Not eligible for Pre-Seed allocation",
    content: [
      'Unfortunately, you are not eligible to request a Community Pre-Seed Round allocation as your Good Crypto account was created after 01/01/22.',
      'However, there will be other opportunities to acquire GOOD before the Public Round for our community and/or to receive a guaranteed allocation in the Public Round.',
      'Stay tuned and wait for the announcements!'
    ]
  }, null, 8, ["type", "content"]))
}